import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
    , children: [
      {
        path: "/",
        name: "HomePage",
        component: () => import('@/views/HomePage.vue'),
        meta: {
          keepAlive: true,
        },

      },
      {
        path: "/about/purpose",
        name: "purpose",
        component: () => import('@/views/about/purposeView.vue')
      },
      {
        path: "/about/talk",
        name: "talk",
        component: () => import('@/views/about/TalkView.vue')
      },
      {
        path: "/about/architecture",
        name: "architecture",
        component: () => import('@/views/about/ArchitectureView.vue')
      },
      {
        path: "/about/liaison",
        name: "liaison",
        component: () => import('@/views/about/LiaisonView.vue')
      },
      {
        path: "/about/constitution",
        name: "constitution",
        component: () => import('@/views/about/ConstitutionView.vue')
      },
      {
        path: "/news",
        name: "news",
        component: () => import('@/views/NewsView.vue')
      },
      // 参加活动 的活動相冊
      {
        path: "/activity/activephotos",
        name: "activephotos",
        component: () => import('@/views/activity/activePhotos.vue')
      },
      // 参加活动
      {
        path: "/activity/activitydetails",
        name: "activitydetails",
        component: () => import('@/views/activity/ActivityDetails.vue')
      },
      // 本会资讯
      {
        path: "/information/newlists",
        name: "newlists",
        component: () => import('@/views/information/NewLists.vue')
      },
      {
        path: "/information/newdetail",
        name: "newdetail",
        component: () => import('@/views/information/NewDetail.vue')
      },
      // 退休組 
      // 退休組資訊
      {
        path: "/retirementgroup/realtimeinfo",
        name: "realtimeinfo",
        component: () => import('@/views/retirementgroup/realTimeInfo.vue')
      },
      // 退休組專欄
      {
        path: "/retirementgroup/specialcolumn",
        name: "specialcolumn",
        component: () => import('@/views/retirementgroup/specialColumn.vue')
      },
      // 退休組活動
      {
        path: "/retirementgroup/retirementgroupactivities",
        name: "retirementgroupactivities",
        component: () => import('@/views/retirementgroup/RetirementGroupActivities.vue')
      },
      // 退休組照片
      {
        path: "/retirementgroup/retirementgroupphotos",
        name: "retirementgroupphotos",
        component: () => import('@/views/retirementgroup/RetirementGroupPhotos.vue')
      },
      // 相关链接
      {
        path: "/relatedlinks",
        name: "relatedlinks",
        component: () => import('@/views/home/RelatedLinks.vue')
      },
      // 会员權益
      {
        path: "/membershipinterests",
        name: "membershipinterests",
        component: () => import('@/views/home/MembershipInterests.vue')
      },
      // 登錄
      {
        path: "/login",
        name: "login",
        component: () => import('@/views/home/LoginView.vue')
      },
      // 主頁
      {
        path: "/homepage",
        name: "homepage",
        component: () => import('@/views/home/HomePage.vue')
      },
      // 修改密碼
      {
        path: "/updatepwd",
        name: "updatepwd",
        component: () => import('@/views/home/UpdatePWD.vue')
      },
      // 忘記密碼 Forgot password
      {
        path: "/forgotpassword",
        name: "forgotpassword",
        component: () => import('@/views/home/ForgotPassword.vue')
      },
      // 入會申請
      {
        path: "/membershipapplication",
        name: "membershipapplication",
        component: () => import('@/views/home/MembershipApplication.vue')
      },
      // 聯絡我們
      {
        path: "/contactus",
        name: "contactus",
        component: () => import('@/views/home/ContactUs.vue')
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
