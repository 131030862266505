import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'

import axios from 'axios'
// 服务端地址
axios.defaults.baseURL = "http://hkcsguadmin.ectg.net/api"
// 独立API  
const app = createApp(App)

app.use(createPinia()).use(router).mount('#app')
// 全局提供属性
app.provide("axios", axios)
app.provide("server_url", axios.defaults.baseURL)

// const token = localStorage.getItem('ocrs-token') || '';
// const adminStore = AdminStore()
// axios拦截器
axios.interceptors.request.use(
    (config) => {
        if (config && config?.headers) {
            const token = localStorage.getItem('ocrs-token') || '';
            config.headers.token = token.replaceAll("\"", "");
        }
        return config
    })
// axios.interceptors.response.use(function (response) {
//     // 对响应成功的数据做点什么
//     // const ip =
//     // response.config.headers = localStorage.getItem('ocrs-token')
//     // console.log('响应成功response.config.headers', response.config.headers);

//     return response
// }, function (error) {
//     // 对响应错误做点什么
//     return Promise.reject(error)

// })